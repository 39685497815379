<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>contacts</md-icon>
              </div>
              <h4 class="title text-center">Nova Senha</h4>
            </md-card-header>

            <md-card-content>
              <ValidationProvider
                name="form.senha"
                :rules="{
                  regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
                  min: 8,
                  required: true,
                  confirmed: 'form.confirmacao'
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Senha</label>
                  <md-icon>lock_outline</md-icon>
                  <md-input
                    ref="senha"
                    v-model="form.senha"
                    type="password"
                    autocomplete="off"
                  >
                  </md-input>
                </md-field>
                <slide-y-down-transition>
                  <span
                    v-show="failed"
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </slide-y-down-transition>
              </ValidationProvider>

              <ValidationProvider
                vid="form.confirmacao"
                name="form.confirmacao"
                rules="min:8|required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Confirmar Senha</label>
                  <md-icon>lock_outline</md-icon>
                  <md-input
                    ref="confirmacao"
                    v-model="form.confirmacao"
                    type="password"
                    autocomplete="off"
                  >
                  </md-input>
                </md-field>
                <slide-y-down-transition>
                  <span
                    v-show="failed"
                    id="error"
                    class="block text-red-600 text-xs absolute bottom-0 left-0"
                    v-if="errors[0]"
                    >{{ errors[0] }}
                  </span>
                </slide-y-down-transition>
              </ValidationProvider>
              <small
                ><br />
                A nova senha deve ter pelo menos 8 caracteres e 1 de cada dos
                seguintes itens: 1x letra maiúscula, 1x letra minúscula, 1x
                número, 1x caractere especial.
              </small>
            </md-card-content>

            <md-card-actions>
              <md-button type="submit" class="md-success small-alert-modal"
                >Nova Senha
              </md-button>
            </md-card-actions>
          </md-card>

          <template>
            <div class="small-alert-modal"></div>
            <modal
              class="small-alert-modal"
              v-if="smallAlertModal"
              @close="smallAlertModalHide"
            >
              <template slot="header"> </template>

              <template slot="body" class="md-danger">
                <p class="modal-text">{{ mensagem }}</p>
              </template>
              <template slot="footer">
                <md-button @click="smallAlertModal = false" class="md-danger"
                  >OK</md-button
                >
              </template>
            </modal>
          </template>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { SlideYDownTransition } from "vue2-transitions";
import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { Modal } from "@/components";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import * as storage from "../storage";
import Vue from "vue";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex =
      "A senha requer 1 de cada dos seguintes itens: 1x letra maiúscula, 1x letra minúscula, 1x número, 1x caractere especial.";
  }
}
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  components: {
    SlideYDownTransition,
    Modal
  },
  props: {
    token: {
      type: String
    }
  },
  created() {
    storage.deleteLocalToken();
    //storage.setLocalToken();
  },
  data() {
    return {
      tokenUrl: this.token,
      form: {
        senha: "",
        confirmacao: ""
      },
      smallAlertModal: false,
      mensagem: ""
    };
  },
  computed: {
    // ...mapGetters("auth", ["hasToken"])
    //...mapActions("auth", ["ActionSignOut"])
  },
  mounted() {
    this.ActionValidarToken(this.tokenUrl).then(response => {
      if (response.status !== 200) {
        this.$router.push({ name: "login" });
      }
    });
  },
  methods: {
    smallAlertModalHide: function() {
      this.classicModal = false;
    },
    ...mapActions("auth", [
      "ActionDoLogin",
      "ActionLoadSession",
      "ActionRecuperarSenha",
      "ActionValidarToken",
      "ActionSetToken"
    ]),
    async submit() {
      try {
        let form = this.form;
        let token = this.tokenUrl;
        await this.ActionRecuperarSenha({ form, token }).then(response => {
          if (response.status === 200) {
            this.mensagem = response.body.mensagem;
            if (!response.body.mensagem.includes("Erro")) {
              if (response.body.mensagem.includes("não encontrado")) {
                Swal.fire({
                  //title: "Sucesso",
                  icon: "warning",
                  text: this.mensagem,
                  customClass: {
                    confirmButton: "md-button md-success btn-fill"
                  },
                  buttonsStyling: false
                });
              } else {
                Swal.fire({
                  //title: "Sucesso",
                  icon: "success",
                  text: this.mensagem,
                  customClass: {
                    confirmButton: "md-button md-success btn-fill"
                  },
                  buttonsStyling: false
                });
              }
              this.ActionSetToken(response.body.jwt).then(() => {
                this.ActionLoadSession().then(() => {
                  window._Vue.$router.push({ name: "Home" });
                });
              });
            } else {
              Swal.fire({
                title: "Erro!",
                icon: "error",
                text: this.mensagem,
                customClass: {
                  confirmButton: "md-button md-danger btn-fill"
                },
                buttonsStyling: false
              });
            }
            this.resetForm();
          }
        });
        //let response = await this.ActionDoLogin(this.form);
        //let response2 = await this.ActionLoadSession();
        // .then(this.$router.push({ name: "Home" }))
        // .catch(this.$router.push({ name: "login" }));
      } catch (err) {
        this.handleError(err);
      }
    },
    resetForm() {
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },

    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 400) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  color: red;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.md-field label {
  left: 25px;
}

#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}
</style>
